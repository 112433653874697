<template>
  <div class="side-menu next">
    <div class="row info-wrapper mob-hide">
      <div class="col-12 mb-4">
        <p class="eoi">EOI Booking</p>
        <h2 class="page-heading">Nirvana Bougainvilla</h2>
        <p class="align-data">Khopoli, Maharashtra</p>
      </div>
    </div>
    <div class="unit-details">
      <div class="variant-detail">
        <span class="unit-title">Selected Variant </span>
        <span class="unit-value">{{selectedFloor.value}}</span>
      </div>
      <div class="variant-detail">
        <span class="unit-title">Area </span>
        <span class="unit-value">{{selectedFloor.area}} sqft</span>
      </div>
      <div class="variant-detail">
        <span class="unit-title">Price Range</span>
        <span class="unit-value">{{selectedFloor.price}}</span>
      </div>
    </div>
    <!-- <div class="platinum-plan">
      <div class="platinum-plan-title">Platinum Plan</div>
      <div class="plan-body">
        <ul class="platinum-list">
          <li ><span class="platinum-feat">₹ 1,00,000 Token Amount <span class="platinum-feat-op">(Refundable)</span></span></li>
           <li ><span class="platinum-feat">confirmed inventory allocation</span></li>
        </ul>
        <span class="terms-conditions">By clicking, you agree to our <a href="#">Terms and conditions</a></span>
        <button @click="selectPlatinum" class="btn btn-block platinum-btn">Book with Platinum</button>
      </div>
    </div> -->
    <div class="platinum-plan gold-plan">
      <div class="platinum-plan-title">Plan</div>
      <div class="plan-body">
        <div class="platinum-list">
          <div  class="custom-control custom-radio ">
            <label class="radio-label" :class="{'active' : planId === 'Gold'}">
              <input type="radio" id="gold-plan" name="planId" class="custom-control-input hidden" @click="selectGold()" :checked="planId !== null && planId === 'Gold'">
              <span class="radio-main-text">₹ 50,000</span>
              <!-- <span class="radio-sub-text">(Refundable Token Amount)</span> -->
              <span class="selection" :class="{'active' : planId === 'Gold'}">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_490_497" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
                <rect width="13" height="13" fill="white"/>
                </mask>
                <g mask="url(#mask0_490_497)">
                <path d="M5.74171 8.99183L9.56046 5.17308L8.80212 4.41475L5.74171 7.47516L4.19796 5.93141L3.43962 6.68975L5.74171 8.99183ZM6.50004 11.9168C5.75074 11.9168 5.04657 11.7746 4.38754 11.4903C3.72851 11.2059 3.15525 10.82 2.66775 10.3325C2.18025 9.84496 1.79431 9.27169 1.50994 8.61266C1.22556 7.95364 1.08337 7.24947 1.08337 6.50016C1.08337 5.75086 1.22556 5.04669 1.50994 4.38766C1.79431 3.72864 2.18025 3.15537 2.66775 2.66787C3.15525 2.18037 3.72851 1.79443 4.38754 1.51006C5.04657 1.22568 5.75074 1.0835 6.50004 1.0835C7.24935 1.0835 7.95351 1.22568 8.61254 1.51006C9.27157 1.79443 9.84483 2.18037 10.3323 2.66787C10.8198 3.15537 11.2058 3.72864 11.4901 4.38766C11.7745 5.04669 11.9167 5.75086 11.9167 6.50016C11.9167 7.24947 11.7745 7.95364 11.4901 8.61266C11.2058 9.27169 10.8198 9.84496 10.3323 10.3325C9.84483 10.82 9.27157 11.2059 8.61254 11.4903C7.95351 11.7746 7.24935 11.9168 6.50004 11.9168Z" fill="white"/>
                </g>
              </svg>
              <span>
                {{ planId === 'Gold' ? 'Selected' : 'Select' }}
              </span>
              </span>
            </label>
          </div>
          <div  class="custom-control custom-radio ">
            <label class="radio-label" :class="{'active' : planId !== 'Gold'}">
              <input type="radio" id="platinum-plan" name="planId" class="custom-control-input hidden" @click="selectPlatinum()" :checked="planId !== null && planId === 'Platinum'">
              <span class="radio-main-text">₹ 1,00,000</span>
              <!-- <span class="radio-sub-text">(Refundable Token Amount)</span> -->
              <span class="selection" :class="{'active' : planId !== 'Gold'}">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <mask id="mask0_490_497" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="13" height="13">
                <rect width="13" height="13" fill="white"/>
                </mask>
                <g mask="url(#mask0_490_497)">
                <path d="M5.74171 8.99183L9.56046 5.17308L8.80212 4.41475L5.74171 7.47516L4.19796 5.93141L3.43962 6.68975L5.74171 8.99183ZM6.50004 11.9168C5.75074 11.9168 5.04657 11.7746 4.38754 11.4903C3.72851 11.2059 3.15525 10.82 2.66775 10.3325C2.18025 9.84496 1.79431 9.27169 1.50994 8.61266C1.22556 7.95364 1.08337 7.24947 1.08337 6.50016C1.08337 5.75086 1.22556 5.04669 1.50994 4.38766C1.79431 3.72864 2.18025 3.15537 2.66775 2.66787C3.15525 2.18037 3.72851 1.79443 4.38754 1.51006C5.04657 1.22568 5.75074 1.0835 6.50004 1.0835C7.24935 1.0835 7.95351 1.22568 8.61254 1.51006C9.27157 1.79443 9.84483 2.18037 10.3323 2.66787C10.8198 3.15537 11.2058 3.72864 11.4901 4.38766C11.7745 5.04669 11.9167 5.75086 11.9167 6.50016C11.9167 7.24947 11.7745 7.95364 11.4901 8.61266C11.2058 9.27169 10.8198 9.84496 10.3323 10.3325C9.84483 10.82 9.27157 11.2059 8.61254 11.4903C7.95351 11.7746 7.24935 11.9168 6.50004 11.9168Z" fill="white"/>
                </g>
              </svg>
              <span>
                {{ planId === 'Gold' ? 'Select' : 'Selected' }}
              </span>
              </span>
            </label>
          </div>
        </div>
        <div class="radio-sub-text">(Refundable Token Amount)</div>
        <div class="alert-badge">
          <span>Hurry Up! 2 Units Left at this price!</span>
        </div>
        <div class="submit-button-section">
        <span class="terms-conditions">By clicking, you agree to our <a href="#" v-b-modal.termsModal>Terms and conditions</a></span>
        <button @click="book" :disabled="!planId" class="btn btn-block platinum-btn">Book</button>
      </div>
      </div>
    </div>
  <div class="disclaimer"><span>Disclaimer:</span> This submission expresses your interest in our property but does not constitute a confirmed booking. The final plot allocation will occur only after completing the formal property booking process.</div>

  <div class="row back-section">
    <button @click="$router.push({name:'Floorplan',query:{...$route.query}})" type="button" class="prevButton">
      <img src="@/assets/images/back-arrow.svg" alt="">
      Back</button>
    </div>
    <div class="col-12 powered-by">Powered by <span class="powered-by-ppistol"> PropertyPistol&nbsp;Technologies</span></div>
<TermsAndConditions/>
</div>
</template>
<script>
import { masterJson } from '@/assets/scripts/utility.js'
export default {
  components:{
    TermsAndConditions:()=>import('@/components/TermsAndConditions.vue')
  },
  methods: {
    selectFloor(floor) {
      this.$store.dispatch('setFloor', floor);
    },
    selectGold(){
      this.$store.dispatch('setAmount',50000);
      this.$store.dispatch('setPlanId','Gold');
    },
    selectPlatinum(){
      this.$store.dispatch('setAmount',100000);
      this.$store.dispatch('setPlanId','Platinum');
    },
    book(){
      this.$store.dispatch('setLoading',true);
      this.$router.push({name:'PaymentDetails',query:{...this.$route.query}});
    }
  },
  mounted(){
    this.selectGold()
  },
  computed: {
    variants() {
      return masterJson.Variants;
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    planId(){
      return this.$store.getters.planId
    }
  }
}
</script>
<style scoped>

.alert-badge{
  background: linear-gradient(92.66deg, #F07D00 12.71%, #E69946 27.25%, #F07D00 44.82%, #E17806 109.04%);
  text-align: center;
  display: block;
  width: fit-content;
  border-radius: 40px;
  padding: 12px 16px;
  margin: 0 auto;
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;
  text-shadow: 0px 2px 2px 0px #00000040;
}

h3 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
}

.token-amount{
  color: #000;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 6px;
}

.token-amount span{
  font-weight: 400;
  opacity: 0.5;
}

.info-wrapper p.eoi{
    font-weight: 600;
    margin-bottom: 12px;
  }

  .disclaimer{
  color: rgba(0, 0, 0, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 20px;
}

.disclaimer span{
  color: #000;
  font-weight: 500;
}

.side-menu{
   border: none;
   padding-bottom: 0 !important;
}

 .info-wrapper{
    margin-bottom: 20px;
    background: #fff;
    padding-right: 15px;
    border-radius: 10px;
    display: flex;
 }

  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 14px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: none;
    opacity: 0.5;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }

.back-section{
  padding-left: 15px;
}
.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
  padding: 0;
}

.prevButton:hover{
  gap: 10px;
}

.unit-details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 13px;
}

.unit-details .unit-title{
  display: block;
  color: #00000080;
  margin-bottom: 6px;
}
.platinum-list{
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
}

.custom-control{
  margin-right: 0;
  flex-grow: 1;
}

.platinum-list .radio-label{
  border: 1px solid rgba(0, 117, 196, 0.2);
  display: block;
  padding: 24px 10px 10px;
  border-radius: 4px;
  color: #000000;
  cursor: pointer;
  position: relative;
  transition: 0.3s
}

.platinum-list .radio-label.active{
  border: 1px solid rgba(0, 117, 196, 1)
}


.platinum-list .radio-label .radio-main-text{
  font-size: 18px;
  font-weight: 600;
  line-height: 24.2px;
  text-align: left;
  margin-bottom: 4px;
  color: rgba(0, 0, 0, 1);
}

.radio-sub-text{
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: center;
  margin-bottom: 16px;
  color: rgba(0, 0, 0, 0.5);
}

.platinum-list .selection{
  position: absolute;
  top: 0;
  left: 10px;
  transform: translateY(-50%);
  background-color: rgba(166, 195, 214, 1);
  border-radius: 30px;
  padding: 4px;
  padding-right: 10px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: 0.3s;
}

.platinum-list .selection.active{
  background-color: rgba(0, 117, 196, 1);
}

.platinum-list .selection span{
  font-size: 13px;
  font-weight: 400;
  line-height: 15.73px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.platinum-list span{
  display: block;
}

.platinum-list .radio-label .radio-sub-text{
  font-size: 12px;
  font-weight: 400;
  color: #00000080;
}
.platinum-list .radio-label img{
  margin-bottom: 3px;
}
.platinum-plan{
  margin-top: 30px;
  border-radius: 10px;
  border: 1px solid rgba(2, 103, 193, 0.20);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
}

.platinum-plan-title{
  padding:16px;
  background: #4948991A;
  border-radius: 10px 10px 0px 0px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.gold-plan .platinum-plan-title{
  background: #0075C41A;
}

.plan-body{
  padding: 16px;
  padding-top: 30px;
}

.plan-body ul{
  list-style-position: inside;
  padding-left: 0;
}

.plan-body ul li{
  list-style-type:none;
  position: relative;
}

.plan-body ul li::before {
  content: '';
  position: absolute;
  height: 6px;
  width: 6px;
  background: #494899;
  transform: rotate(45deg);
  top: 8px;
  left: 0;
}

.gold-plan .plan-body ul li::before{
  background: #0075C4;
}

.plan-body li .platinum-feat{
  position: relative;
  left: 16px;
}
.platinum-feat{
  color: #000000;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
}

.platinum-feat .platinum-feat-op{
  font-weight: 400;
  color: #00000080;
}

.platinum-btn{
  background: #494899;
  border-radius: 4px;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
}

.platinum-btn:focus, .platinum-btn.focus{
  box-shadow: none;
}
.platinum-btn:hover, .platinum-btn:active{
  color: #ffffff;
}

.gold-plan .platinum-btn{
  background-color: #0075C4;
}

.gold-plan .platinum-btn:hover{
  background: #0692f0;
  border-color:#0692f0;
}
.unit-details .unit-value{
  display: block;
  color: #000000;
  font-weight: 500;
}

.terms-conditions{
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: inline-block;
  text-align: center;
  width: 100%;
  margin-bottom: 16px;
  color: #000;
}

.terms-conditions a{
  color: #000;
  font-weight: 600;
}
.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: auto;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

@media (max-width:991px) {
  .plan-body{
    padding-top: 16px;
  }
  .disclaimer{
    margin-left: 16px;
    margin-right: 16px;
  }
  .powered-by{
    display: none;
  }
  .mob-hide{
    display: none;
  }
  .prevButton{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }

  .info-wrapper{
    margin-left: 20px;
    margin-right: 16px;
    padding-right: 0;
  }
  .info-wrapper .col-9{
    padding-left: 0;
  }
  .unit-details{
    padding-left: 20px;
    padding-right: 16px;
  }
  .platinum-plan{
    border-radius: 6px;
    margin: 16px 16px 0;
  }
  .token-amount{
    justify-content: center;
  }
  .platinum-plan-title{
    border-radius: 0;
  }
  .back-section{
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }

  .submit-button-section{
    position: fixed;
    bottom: 0;
    padding: 16px;
    background: #ffffff;
    left: 0;
    z-index:1003;
    width: 100%;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }
}

@media (min-width: 992px) and (max-width: 1400px){
  .platinum-list{
    gap: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1199px){
  .platinum-list .selection span{
    font-size: 12px;
    line-height: 1;
  }
}


@media (max-width: 460px){
  .platinum-list{
    gap: 8px;
  }

  .platinum-list .selection span{
    font-size: 12px;
    line-height: 1;
  }
}


</style>